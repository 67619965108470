import React from "react";
import { Link, useLocation } from "react-router-dom";
import { isProduction } from "../lib/aleoService";


// Assets
import enigmaLogo from "../images/Enigma-Small.png";

const navList = [
  {
    name: "Play",
    link: "/play",
  },
  {
    name: "Instructions",
    link: "/instructions/",
  },
  {
    name: "Hints",
    link: "/hints/",
  },
  {
    name: "Toolkit",
    link: "/toolkit/",
  },
  {
    name: "Leaderboard",
    link: "/leaderboard/",
  }
];

const navPlayList = [
  {
    name: "Login",
    link: "/play/login/",
  },
  {
    name: "Prompt.001",
    link: "/play/prompt/",
  },
  {
    name: "Decrypt.001",
    link: "/play/decrypt/",
  },
  {
    name: "Prompt.002",
    link: "/play/prompt2/",
  },
  {
    name: "Prompt.003",
    link: "/play/prompt3/",
  },
  {
    name: "Decrypt.002",
    link: "/play/decrypt2/",
  },
  {
    name: "Success",
    link: "/play/success/",
  },
]

const navStyles = {
  display: "flex",
  gap: "0 48px",
  flexWrap: "wrap",
}

const listStyles = {
  display: "flex",
  
  gap: "48px",
  textTransform: "uppercase",
  textAlign: "center",
  fontSize: "20px",
  letterSpacing: "1px",
  height: "32px",
  marginBottom: "8px",  
  // lineHeight: "48px",
}

const headerStyles = {
  display: "flex",
  width: "calc(100% - 80px)",
  maxWidth: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "40px 40px 10px 40px"
}

const logoStyles = {
  width: "189px",
  height: "40px",
}

const subNavStyles = {
  display: "flex",
  justifyContent: "center",
  width: "calc(100% - 80px)",
  margin: "0 40px",
}

const SubNav = ({ currentPath }) => { 
  return (
    <div style={subNavStyles}>
      <ul style={navStyles}>
        {navPlayList.map((navItem, index) => (
          <li key={index} style={listStyles} className={`nav-item ${currentPath.includes(navItem.link) ? 'active-link' : ''}`}>
            <Link className="nav-link" to={navItem.link}>{navItem.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const Header = () => {
  const currentPath = useLocation().pathname;

  return (
    <>
      <header className="enigma-header" style={headerStyles}>
        <Link to="/">
          <img src={enigmaLogo} alt="Enigma by Aleo" style={logoStyles} />
          </Link>
        <nav>
          <ul style={navStyles}>
            {navList.map((navItem, index) => (
              <li key={index} style={listStyles} className={`nav-item ${currentPath.includes(navItem.link) ? 'active-link' : ''}`}>
                <Link className="nav-link" to={navItem.link}>{navItem.name}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </header>
      {(!isProduction() && currentPath.includes('play')) && <SubNav currentPath={currentPath} />}
      
    </>
  );
};

export default Header;
