import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";


// type LayoutProps = {
//   children: ReactNode;
// };

const contentStyles = {
  display: "flex",
  margin: "0 40px",
  paddingTop: "35px",
  justifyContent: "center",
}

const layoutWrapperStyles = {
  position: "relative",
  minHeight: "calc(100vh - 40px)",
}

const mobileTextStyles = {
  color: "#F5F5F5",
  fontSize: "36px",
  letterSpacing: "1.6px",
  lineHeight: "1.35",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "calc(100vh - 100px)",
}

const Layout = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(1000);
  useEffect(() => {
    if (typeof window === 'undefined') return;
    setWindowWidth(window.innerWidth);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={ layoutWrapperStyles }>
      {windowWidth >= 600 ? (
        <>
          <Header />
          <main style={contentStyles} className="layout-content">{children}</main>
        </>
      ) : (
          <p style={mobileTextStyles} className="aleo-font">
            <span className="gradient-text">Enigma</span> is meant to be played on a desktop device.<br />
            thank you!
        </p>
      )}
      
      <Footer />
    </div>
  );
};

export default Layout;
