import React, { useEffect, useState } from "react";

// Components
import Layout from "../components/Layout";

import Spinner from "../components/Spinner";
import { calculatePlayerScores } from "../lib/aleoService";


const leaderboardStyles = {
  maxWidth: "1075px",
  width: "100%",
  textAlign: "center",
  borderCollapse: "collapse",
}

const loadingTextStyles = {
  marginTop: "20px",
}

const scoreStyles = {
  textAlign: "left",
  color: "transparent",
}

const LeaderboardPage = () => {
  const [players, setPlayers] = useState([]);
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  
  useEffect(() => {
    const fetchCounterData = async () => {
      try {
        setLoading(true);
        const { players, scores } = await calculatePlayerScores();
        setLoading(false);
        if (scores.length === 0) {
          throw new Error("Failed to fetch leaderboard");
        } 
        setScores(scores);
        setPlayers(players);
      } catch (error) {
        console.log(error);
        setLoading(false);
        if (error.message) {
          setErrorMessage('Error: ' + error.message);
        }
      }
    };

    fetchCounterData();
  }, []);


  return (
    <>
      <div>
        <Layout>
          <section style={leaderboardStyles}>
            {loading ? <>
              <Spinner />
              <div className="aleo-font" style={loadingTextStyles}>Loading Leaderboard</div>
               </>
              : errorMessage ? (
              // Render error message if there is an error
              <div className="error-message aleo-font">{errorMessage}</div>
            ) :
              <>
                
                <table className="table-leaderboard">
                <thead>
                  <tr>
                    <th>Latest Block</th>
                    <th>Player</th>
                    <th>Score</th>
                  </tr>
                </thead>
                <tbody>
                  {players.map((data) => (
                    <tr key={data.player}>
                      <td>{data.blockHeight}</td>
                      <td>{data.player}</td>
                      <td>{data.score}</td>
                    </tr>
                  ))}
                </tbody>
                  </table>
                  <div className="aleo-font" style={scoreStyles}>
                    <div>Completed:</div>
                    <div>stage 1: {scores.stage1}</div>
                    <div>stage 2: {scores.stage2}</div>
                    <div>stage 3: {scores.stage3}</div>
                    <div>stage 4: {scores.stage4}</div>
                  </div>
              </>              
            }
          </section>
      </Layout>
      </div>
    </>
  )
}

export default LeaderboardPage