import React from "react"

// Components
import Button from "../../components/Button"
import Layout from "../../components/Layout"

const PromptPage= () => {
  return (
    <>
      <div>
        <Layout>
          <div className="vertical-form">
            <div className="subheading">NO. 001</div>
            <div className="prompt">The gamemaster has sent you a secret message. Find it, and decrypt it to reveal the first clue.</div>
            <Button to="/play/decrypt" text="Continue" alt="Continue" />
          </div>
      </Layout>
      </div>
    </>
  )
}

export default PromptPage
