// React component for a square button with an outlined look
//
// Path: app/src/components/button.tsx

import { Link } from "react-router-dom"

// Properties for the button
// interface ButtonProps {
//   to: string
//   text: string
//   alt: string
// }

export const buttonStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "uppercase",
  width: 320,
  height: 80,
  fontSize: "20px",
  margin: "0 auto",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "#F5F5F5",
  textDecoration: "none",
  letterSpacing: "1px",
  marginTop: "0px",
  backgroundColor: "#121212",
  color: "#F5F5F5",
  cursor: "pointer",
  
}

// Button Component that returns a button with a link
const Button = (props) => {
  return (
    <Link to={props.to} style={buttonStyles} className="cta-button">
      {props.text}
    </Link>
  )
}

export default Button