import React from "react"

// Components
import Button from "../../components/Button"
import Layout from "../../components/Layout"

const PromptPage2 = () => {
  return (
    <>
      <div>
        <Layout>
          <div className="vertical-form">
            <div className="subheading">NO. 002</div>
            <div className="prompt">
              <div>Amidst verdant walls, where puffs of white blend,</div>
              <div>Four words await, a spot some like to spend.</div>
              <div>Don't overlook it’s still there in plain sight</div>
              <div>Seek out this niche, be cunning and bold,</div>
              <div>The answer you seek will soon unfold.</div>
            </div>
            <Button to="/play/prompt3" text="Continue" alt="Continue" />
          </div>
      </Layout>
      </div>
    </>
  )
}

export default PromptPage2