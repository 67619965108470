import React, { useEffect, useState } from "react";
import Button from "../components/Button";

// assets
import logo from "../images/Aleo-Logo.png";
import enigmaLogo from "../images/Enigma-Big.png";

const isBrowser = typeof window !== 'undefined';

const pageStyles = {
  backgroundColor: "#121212",
  height: "100vh",
  maxWidth: "100%",
  padding: "0 16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}
const presentsStyles = {
  color: "#F5F5F5",
  fontSize: "20px",
  letterSpacing: "10px",
  textAlign: "center",
}
const mobileTextStyles = {
  color: "#F5F5F5",
  fontSize: "14px",
  letterSpacing: ".7px",
  lineHeight: "1.35",
  textAlign: "center",
}

const logoStyles = {
  width: "86px",
  height: "auto",
  marginBottom: 24,
}

const imgStyles = {
  maxWidth: "100%",
  height: "auto",
  width: "auto",
  margin: "64px auto",
}


const IndexPage = () => {
  const [windowWidth, setWindowWidth] = useState(1000);

  useEffect(() => {
    if (!isBrowser) return;
    setWindowWidth(window.innerWidth);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <main style={pageStyles}>
      <img src={logo} alt="Aleo" style={logoStyles} />
      <div style={presentsStyles} className="aleo-font">
        Presents
      </div>
      <img src={enigmaLogo} alt="Enigma by Aleo" style={imgStyles} />
      <div>
      {windowWidth >= 500 ? (
        <Button to="/play/login/" text="Start" alt="Get Started" />   
      ) : (
        <p style={mobileTextStyles} className="aleo-font">Visit Me on Your Laptop</p>
      )}
      </div>
    </main>
  )
}

export default IndexPage