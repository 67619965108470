import React from "react";

export let inputStyles = {
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
  width: 640,
  fontSize: "20px",
  margin: "0 auto",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "#F5F5F5",
  textDecoration: "none",
  letterSpacing: "1px",
  marginTop: "0px",
  backgroundColor: "#121212",
  color: "#F5F5F5",
  lineHeight: "1.5",
  padding:"32px"
}


const Input = (props) => {
  // const [formMessage, setFormMessage] = useState("");
  const derivedStyles = {
    textAlign: props.align,
    borderColor: props.state === "success" ? "#C4FFC2" : props.state === "error" ? "#FFA978" : "#F5F5F5"
  };
  inputStyles = { ...inputStyles, ...derivedStyles };
  const hintStyles= {
    textAlign: "center",
    padding: "32px",
    minHeight: "16px",
    color: props.state === "success" ? "#C4FFC2" : props.state === "error" ? "#FFA978" : "#F5F5F5"
  };

  return (
    <>
      <textarea
        className="aleo-textarea"
        style={inputStyles}
        placeholder={props.placeholder}
        rows={props.rows}
        cols={50}
        value={props.value}
        onChange={props.onChange}
      />
      <div className="aleo-input-message" style={hintStyles}>
          {props.hint}
      </div>
    </>
  )
}

export default Input