import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import init, { string_to_field_strings } from '../../lib/string_to_fields/string_to_fields.js';

// Components
import { buttonStyles } from "../../components/Button.jsx";
import Input from "../../components/Input.jsx";
import Layout from "../../components/Layout.jsx";

// Assets
import Spinner from "../../components/Spinner.jsx";
import arrow from "../../images/Arrow.svg";
import { checkGameStarted, postMessagePromise } from "../../lib/aleoService.js";
import { aleoSrc } from "../../lib/constants.js";
import { getLocalStorageItem } from "../../lib/localStorage.js";

const formButtonStyles = { ...buttonStyles }
const instructionsText = ""
const decryptId = "002";

const spinnerWrapperStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "40px",
}

const Decrypt2Page = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [instructions, setInstructions] = useState(instructionsText);
  const [loading, setLoading] = useState(false);
  const [worker, setWorker] = useState(null);
  const [privateKey, setPrivateKey] = useState("");
  const [formStatus, setFormStatus] = useState("default");
  const [formHint, setFormHint] = useState("");
  const [transactionAccepted, setTransactionAccepted] = useState(false);

  // Initialize the Web Worker when the component mounts
  useEffect(() => {
    if (worker === null) {
        const spawnedWorker = spawnWorker();
        setWorker(spawnedWorker);
        return () => {
            spawnedWorker.terminate();
        };
    }
  }, []);

  // Check if the user has a private key stored in local storage
  useEffect(() => {
    const storedKey = getLocalStorageItem("privateKey");
    const storedAccount = getLocalStorageItem("account");
    if (!storedKey) {
      setFormHint("Error: No Private Key Found. Please Login.")
      setFormStatus("error")
      return
    }
    setPrivateKey(storedKey);
    if (storedAccount) {
      naviateToGameStatus(storedAccount);
    }
  }, []);

   // Initialize the Wasm module when the component mounts
   useEffect(() => {
    init().catch(e => {
      console.error('Error initializing the wasm module:', e);
    });
   }, []);
  
   const spawnWorker = () => {
    const worker = new Worker(new URL('../../lib/worker.js', import.meta.url), {
      type: 'module',
    });
    worker.addEventListener('message', (ev) => {
      if (ev.data.type == 'EXECUTION_TRANSACTION_COMPLETED') {
        const transactionId = ev.data.executeTransaction;
        setFormHint("Request Successful: Transaction ID: " + transactionId);
        setLoading(false);
      } else if (ev.data.type == 'ERROR') {
        setLoading(false);
      } else if (ev.data.type == 'ALEO_WORKER_READY') {
        console.log('ALEO_WORKER_READY');
      }
    });
    return worker;
   };
  
   const naviateToGameStatus = async (account) => {
    const status = await checkGameStarted(account);
    console.log('status', status);
      if (status === "notStarted") {
        navigate("/play/login");
      } else if (status === "issued" || status === "requested") {
        // navigate("/play/prompt");
      } else if (status === "finished") {
        navigate("/play/success");
      }
  }

  // Execute Aleo Program
  const execute = async (v0) => {
    setLoading(true);
    try {
      console.log('execute()');
           
      const program = aleoSrc;
      const functionName = "finish";
      const inputs = v0;
      const fee = 1;
      const peerUrl = "https://api.explorer.aleo.org/v1";
      const privateFee = false;
      const feeRecord = false;
      setFormStatus("default");
      setFormHint("SUBMITTING MESSAGE");

      const requestFunction = await postMessagePromise(worker, {
          type: "ALEO_EXECUTE_PROGRAM_ON_CHAIN",
          remoteProgram: program,
          aleoFunction: functionName,
          inputs: inputs,
          privateKey: privateKey,
          fee: fee,
          privateFee: privateFee,
          feeRecord: feeRecord,
          url: peerUrl,
      });
      if (requestFunction.type == 'ERROR') {
        throw new Error(requestFunction.errorMessage);
      } else if (requestFunction.type == 'EXECUTION_TRANSACTION_COMPLETED') {
        console.log('txid', requestFunction.executeTransaction);
        setLoading(true);
        // setFormStatus("success");
        setFormHint(waitingCopy);
        const isReadyToBegin = await pollForMappingStatus(account, 'finish_index');
        // const transactionSuccess = await pollTransactionStatus(requestFunction.executeTransaction);
        if (isReadyToBegin) {
          setTransactionAccepted(true);
          setFormHint("Request Successful. Press CONTINUE For Next Clue.");
          setFormStatus("success");
        } else {
          setFormHint("Error: Transaction Timeout. Please check blockchain for status");
          setFormStatus("error");
          setLoading(false);
        }
      }
        
    } catch (error) {
      setLoading(false);
      setFormStatus("error");
      setFormHint("Error: Transaction Failed. Please Try Again.")
    }
  };
  
   const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      // Ensure Wasm module is initialized before calling functions
      await init();

      // Call the WebAssembly function with the message
      const fieldStrings = string_to_field_strings(message);

      // Loop through the field strings and set the instructions
      if (fieldStrings.length > 0) {
        let stringFields = "[";
        for (let i = 0; i < fieldStrings.length; i++) {
          stringFields += fieldStrings[i].toString();
          if (i < fieldStrings.length - 1) {
            stringFields += ", ";
          } else {
            stringFields += "]";
          }
        }
        const stringToArray = [stringFields]
        
        await execute(stringToArray);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error WASM:", error);
      setLoading(false);
      const spawnedWorker = spawnWorker();
      setWorker(spawnedWorker);
    }
   };
  
   const handleContinue = () => {
    navigate("/play/success");
  }
  
  return (
    <>
      <div>
        <Layout>
          <form className="vertical-form" onSubmit={handleSubmit}>
            <div className="sub-nav">
              <Link to="/play/prompt3" className="left-nav">
                <img src={arrow} alt="arrow" className="arrow" />
                <span className="back aleo-font">Back</span>
              </Link>
              <div className="subheading center-nav">NO. {decryptId }</div>
              <span className="right-nav aleo-font">Help</span>
            </div>
            <Input hint={formHint} rows={6} placeholder="ENTER YOUR MESSAGE" align="left" state={formStatus} value={message} onChange={(e) => setMessage(e.target.value)} />
            <div style={spinnerWrapperStyles}>
              {loading && <Spinner />}
            </div>
            <div className="action-buttons">
              <button type="submit" className="cta-button" style={formButtonStyles}>Submit</button>
              <button disabled={!transactionAccepted} className="cta-button" style={formButtonStyles}  onClick={handleContinue}>Continue</button>
            </div>
          </form>
        </Layout>
      </div>
    </>
  )
}

export default Decrypt2Page