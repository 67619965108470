import { Route, Routes } from 'react-router-dom';

import NotFoundPage from './pages/404';
import HintsPage from './pages/hints';
import HomePage from './pages/home';
import InstructionsPage from './pages/instructions';
import LeaderboardPage from './pages/leaderboard';
import ToolkitPage from './pages/toolkit';

import DecryptPage from './pages/play/decrypt';
import Decrypt2Page from './pages/play/decrypt2';
import LoginPage from './pages/play/login';
import PromptPage from './pages/play/prompt';
import Prompt2Page from './pages/play/prompt2';
import Prompt3Page from './pages/play/prompt3';
import SuccessPage from './pages/play/success';

const AppRouter = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/toolkit" element={<ToolkitPage />} />
    <Route path="/instructions" element={<InstructionsPage />} />
    <Route path="/hints" element={<HintsPage />} />
    <Route path="/leaderboard" element={<LeaderboardPage />} />
    <Route path="/play/" element={<LoginPage />} />
    <Route path="/play/login" element={<LoginPage />} />
    <Route path="/play/prompt" element={<PromptPage />} />
    <Route path="/play/prompt2" element={<Prompt2Page />} />
    <Route path="/play/prompt3" element={<Prompt3Page />} />
    <Route path="/play/decrypt" element={<DecryptPage />} />
    <Route path="/play/decrypt2" element={<Decrypt2Page />} />
    <Route path="/play/success" element={<SuccessPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default AppRouter;
