import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAleoWASM } from "../../lib/aleo-wasm-hook";
import { getLocalStorageItem, setLocalStorageItem } from "../../lib/localStorage";

// Components
import { buttonStyles } from "../../components/Button";
import Input from "../../components/Input";
import Layout from "../../components/Layout";

// global style
import Spinner from "../../components/Spinner";
import { checkGameStarted, pollForMappingStatus, postMessagePromise } from "../../lib/aleoService";
import { aleoSrc } from "../../lib/constants";

const formButtonStyles = { ...buttonStyles, marginTop: "0px" }
const formStyles = {
  marginTop: "0px",
}
const subHeadingStyles = {
  marginBottom: "60px"
}

const spinnerWrapperStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "40px",
}

const hint = "Hint: Private Key Starts with APrivateKey1";


const LoginPage= () => {
  const navigate = useNavigate();
  const [privateKey, setPrivateKey] = useState("");
  
  const [formStatus, setFormStatus] = useState("default");
  const [aleo] = useAleoWASM();
  const [formHint, setFormHint] = useState(hint);
  const [loading, setLoading] = useState(false);
  const [worker, setWorker] = useState(null);
  const [transactionAccepted, setTransactionAccepted] = useState(false);
  // const [gameStatus, setGameStatus] = useRef("notStarted");

  useEffect(() => {
    if (worker === null) {
        const spawnedWorker = spawnWorker();
        setWorker(spawnedWorker);
        return () => {
            spawnedWorker.terminate();
        };
    }
  }, []);

  // Check if Existing Account has already started the game
  useEffect(() => {
    const storedAccount = getLocalStorageItem("account");
    if (storedAccount) {
      naviateToGameStatus(storedAccount);  
    }
  }, []);

  const naviateToGameStatus = async (account) => {
    const status = await checkGameStarted(account);
    console.log('status', status);
      // setGameStatus(status);
      if (status === "requested" || status === "issued") {
        navigate("/play/prompt");
      } else if (status === "submitted") {
        navigate("/play/prompt2");
      } else if (status === "finished") {
        navigate("/play/success");
      }
  }

  function spawnWorker() {
    let worker = new Worker(
        new URL("../../lib/worker.js", import.meta.url),
        { type: "module" }
    );
    worker.addEventListener("message", (ev) => {
        if (ev.data.type == "OFFLINE_EXECUTION_COMPLETED") {
            setLoading(false);
        } else if (ev.data.type == "EXECUTION_TRANSACTION_COMPLETED") {
            const transactionId = ev.data.executeTransaction;
            setLoading(false);
            
        } else if (ev.data.type == "ERROR") {
            setLoading(false);
            
        }
    });
    return worker;
}
  
  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setAccountFromPrivateKey(null);
    setLoading(true);
    try {
      // Derive account from private key
      if (!aleo) return;
      let account = aleo.PrivateKey.from_string(privateKey);
      if (!account) {
        throw new Error("Invalid private key");
      }
      account = account.to_address().to_string();
      console.log("account: " + account);
      
      // Update form status
      setFormStatus("success");
      setFormHint("Private Key is Valid. Starting Game. This can take a few minutes.");

      // Set private key / account in local storage
      setLocalStorageItem("account", account);
      setLocalStorageItem("privateKey", privateKey);

      // Detect if account has already started the game
      const gameStatus = await checkGameStarted(account);
      console.log('gameStatus', gameStatus);
      if (gameStatus !== "notStarted") {
        naviateToGameStatus(account);
        return;
      }

      // If account has not started the game, request() to start the game
      await execute(account);

      // setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setFormStatus("error");
      if (error.message) {
        setFormHint(error.message);  
      } else {
        setFormHint(error);
      }
      const spawnedWorker = spawnWorker();
      setWorker(spawnedWorker);
      
    }
  };

  // Execute Aleo Program
  const execute = async (account) => {
    setLoading(true);
    try {
      console.log('execute');
      
      const program = aleoSrc;
      const functionName = "request";
      const inputs = false;
      const fee = 1;
      const peerUrl = "https://api.explorer.aleo.org/v1";
      const privateFee = false;
      const feeRecord = false;

      const requestFunction = await postMessagePromise(worker, {
          type: "ALEO_EXECUTE_PROGRAM_ON_CHAIN",
          remoteProgram: program,
          aleoFunction: functionName,
          inputs: inputs,
          privateKey: privateKey,
          fee: fee,
          privateFee: privateFee,
          feeRecord: feeRecord,
          url: peerUrl,
      });
      if (requestFunction.type == 'ERROR') {
        throw new Error(requestFunction.errorMessage);
      } else if (requestFunction.type == 'EXECUTION_TRANSACTION_COMPLETED') {
        setLoading(true);
        console.log('tx', requestFunction);
        setFormStatus("default");
        setFormHint('SUBMISSION IN REVIEW. THIS CAN TAKE 5 MINUTES.');
        // chagne this from 0 to 1
        const isReadyToBegin = await pollForMappingStatus(account, 'issue_index');
        console.log('isReadyToBegin', isReadyToBegin);
        if (isReadyToBegin) {
          setTransactionAccepted(true);
          setFormHint("Request Successful. Press CONTINUE to begin.");
          setFormStatus("success");
          setLoading(false);
        }
      }
        
    } catch (error) {
      setLoading(false);
      setFormStatus("error");
      setFormHint(error.message);
    }
  };

  const handleInputChange = (event) => {
    setPrivateKey(event.target.value)
  }


  const handleContinue = () => {
    console.log('handleContinue');
    navigate("/play/prompt");
  }
  
  return (
    <>
      <div>
        <Layout>
          <form className="vertical-form" style={formStyles} onSubmit={handleSubmit}>
            <div className="subheading center-nav" style={subHeadingStyles}>Login to Enigma</div>
          <Input 
              rows={2} 
              placeholder="TESTNET PRIVATE KEY"
              state={formStatus}
              hint={formHint}
              align="center"
              value={privateKey}
              onChange={event => handleInputChange(event)}
            />
            <div style={spinnerWrapperStyles}>
              {loading && <Spinner />}
            </div>
            <div className="action-buttons">
              <button disabled={privateKey === ""} type="submit" className="cta-button" style={formButtonStyles}>Login</button>
              <button disabled={!transactionAccepted} className="cta-button" style={formButtonStyles} onClick={handleContinue}>Continue</button>
            </div>
            
          </form>
      </Layout>
      </div>
    </>
  )
}

export default LoginPage

// export const Head: HeadFC = () => <title>Enigma by Aleo</title>
