// aleoService.ts
// Service to interact with the Aleo blockchain.
import axios from 'axios';
import {
  ALEO_API_BASE_URL as aleoAPIBaseUrl,
  ALEO_PROGRAM_ID as aleoProgramId,
  gameStages,
  mappingNames,
} from './constants.js';

// Determine if production or development environment
export const isProduction = () => {
  const hostname = window && window.location && window.location.hostname;
  return hostname === 'enigma.aleo.org';
};

// Retrieve the program source code from the Aleo blockchain.
export const getEnigmaProgram = () => {
  try {
    return axios
      .get(`${aleoAPIBaseUrl}/program/${aleoProgramId}`)
      .then((response) => {
        if (response.data === null) {
          return null;
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  } catch (error) {
    console.error(error);
  }
};
// Retrieve the value of a mapping from the Aleo blockchain.
// Return the value of the mapping.
export const getMappingValue = (mappingName, mappingKey) => {
  try {
    return axios
      .get(
        `${aleoAPIBaseUrl}/program/${aleoProgramId}/mapping/${mappingName}/${mappingKey}`
      )
      .then((response) => {
        if (response.data === null) {
          return null;
        } else {
          return response.data;
        }
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  } catch (error) {
    console.error(error);
  }
};
// Retrieve the values of all mappings from the Program on the Aleo blockchain.
// Return key-value pairs of mapping names and values.
export const getAllMappings = (mappingKey) => {
  try {
    const promises = mappingNames.map((mappingName) =>
      getMappingValue(mappingName, mappingKey)
    );
    return Promise.all(promises).then((values) => {
      const mappings = {};
      for (let i = 0; i < mappingNames.length; i++) {
        mappings[mappingNames[i]] = values[i];
      }
      console.log(mappings);
      return mappings;
    });
  } catch (error) {
    console.error(error);
  }
};
// Get the value of the counter mapping from the Aleo blockchain.
// Return the value of the counter after resolving the promise.
export const getCounters = async (key, mapping) => {
  try {
    const players = [];
    const value = await getMappingValue('counters', key);

    // value will be a string like "2u32" or "9u32"
    // loop through the number of "u" and getMappingValue for each key
    if (value !== undefined) {
      // split the string at u32 to determine the number of players
      if (typeof value !== 'string') {
        return players;
      }
      const numberOfPlayers = value.split('u32')[0];
      for (let i = 0; i < numberOfPlayers; i++) {
        const playerResponse = await getMappingValue(mapping, `${i}u32`);
        if (!playerResponse) {
          continue;
        }
        // Parse the response to get the player's address
        const jsonString = playerResponse
          .replace(/\n/g, '') // Remove new lines
          .replace(/,\s*}/, '}'); // Remove any trailing commas before closing braces
        // console.log(jsonString);
        const playerAddress = jsonString.split(',')[0].split(':')[1].trim();
        let blockHeight = jsonString.split(',')[1].split(':')[1].trim();
        blockHeight = blockHeight.split('u32')[0];

        // console.log(blockHeight);
        players.push({ address: playerAddress, blockHeight: blockHeight });
      }
      return players;
    } else {
      throw new Error('Value is undefined');
    }
  } catch (error) {
    console.error(error);
  }
};
// Helper function to update or add a player's score
const updatePlayerScore = (players, playerAddress, blockHeight, scoreToAdd) => {
  const player = players.find((p) => p.player === playerAddress);
  if (player) {
    player.score += scoreToAdd;
    if (player.blockHeight < blockHeight) {
      player.blockHeight = blockHeight;
    }
  } else {
    players.push({
      player: playerAddress,
      score: scoreToAdd,
      blockHeight: blockHeight,
    });
  }
};
// Get counters and calculate the players' scores
export const calculatePlayerScores = async () => {
  const players = [];
  const scores = {
    stage1: 0,
    stage2: 0,
    stage3: 0,
    stage4: 0,
  };
  // for loop with an index to get the mapping name and score
  for (let i = 0; i < gameStages.length; i++) {
    const playerAddresses = await getCounters(
      gameStages[i].counterKey,
      gameStages[i].mapping
    );
    if (playerAddresses) {
      playerAddresses.forEach((playerAddress) => {
        updatePlayerScore(
          players,
          playerAddress.address,
          playerAddress.blockHeight,
          gameStages[i].score
        );
      });
      scores[`stage${i + 1}`] = playerAddresses.length;
    }
  }
  // sort players by score
  // players.sort((a, b) => b.score - a.score);
  // sort players by score descending and block height ascending
  players.sort((a, b) => {
    if (b.score === a.score) {
      return a.blockHeight - b.blockHeight;
    }
    return b.score - a.score;
  });
  return { players, scores };
};

// Post a message to a web worker and return a promise that resolves when the
// worker posts a message back.
export const postMessagePromise = (worker, message) => {
  console.log('Web worker: Posting message...');
  return new Promise((resolve, reject) => {
    worker.onmessage = (event) => {
      resolve(event.data);
    };
    worker.onerror = (error) => {
      reject(error);
    };
    worker.postMessage(message);
  });
};

// Check if the user has started the game and route accordingly
export const checkGameStarted = async (account) => {
  const mappings = await getAllMappings(account);
  if (mappings) {
    console.log(mappings);
    if (mappings.finish_index) {
      return 'finished';
    } else if (mappings.submit_index) {
      return 'submitted';
    } else if (mappings.issue_index) {
      return 'issued';
    } else if (mappings.request_index) {
      return 'requested';
    }
  }
  return 'notStarted';
};

// poll the getAllMappings function to check if the transaction has been confirmed
export const pollForMappingStatus = async (account, mappingKey) => {
  let confirmed = false;
  let count = 0;
  while (!confirmed && count < 6) {
    const mappings = await getAllMappings(account);
    if (mappings) {
      console.log(mappings);
      if (mappings[mappingKey]) {
        confirmed = true;
      }
    }
    count++;
    // wait 10 seconds before trying again
    await new Promise((resolve) => setTimeout(resolve, 30000));
  }
  return confirmed;
};

export const pollTransactionStatus = async (txId) => {
  try {
    let confirmed = false;
    let count = 0;
    while (!confirmed && count < 20) {
      const response = await axios.get(`${aleoAPIBaseUrl}/transaction/${txId}`);

      if (response.data) {
        if (response.data.execution.transitions) {
          for (let i = 0; i < response.data.execution.transitions.length; i++) {
            const transition = response.data.execution.transitions[i];
            if (transition.program === aleoProgramId) {
              confirmed = true;
            }
          }
        } else {
          return false;
        }
      }
      count++;
      await new Promise((resolve) => setTimeout(resolve, 30000));
    }
    return confirmed;
  } catch (error) {
    console.error(error);
    if (error.response.status === 500) {
      // wait 10 seconds before trying again
      await new Promise((resolve) => setTimeout(resolve, 10000));
      // return pollTransactionStatus(txId);
    }
  }
};
