// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("aleofontsans-regular-webfont.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("aleofontsans-regular-webfont.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 16, 2023 */



@font-face {
    font-family: 'aleofont_sansregular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
         url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
    font-weight: normal;
    font-style: normal;

}`, "",{"version":3,"sources":["webpack://./src/fonts/webfontkit-AleofontSans/stylesheet.css"],"names":[],"mappings":"AAAA,+EAA+E;;;;AAI/E;IACI,mCAAmC;IACnC;+DAC4D;IAC5D,mBAAmB;IACnB,kBAAkB;;AAEtB","sourcesContent":["/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 16, 2023 */\n\n\n\n@font-face {\n    font-family: 'aleofont_sansregular';\n    src: url('aleofontsans-regular-webfont.woff2') format('woff2'),\n         url('aleofontsans-regular-webfont.woff') format('woff');\n    font-weight: normal;\n    font-style: normal;\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
