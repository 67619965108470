import React from "react"

// Components
import Layout from "../components/Layout"



const contentBlockStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "uppercase",
  width: 300,
  height: 300,
  fontSize: "32px",
  borderWidth: "1px",
  gap: "16px",
  borderStyle: "solid",
  borderColor: "#F5F5F5",
  letterSpacing: "1.6px",
  textAlign: "center",
}

const contentBlocksStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "40px",
  marginTop: "40px",
  maxWidth: "100%",
  width: "calc(100% - 80px)",
}
const contentBlocks = [
  {
    name: "Aleo SDK",
    link: "https://developer.aleo.org/sdk",
  },
  {
    name: "Aleo 123",
    link: "https://aleo123.io/",
  },
  {
    name: "LEO Docs",
    link: "https://developer.aleo.org/leo/",
  },
  {
    name: "Explorer",
    link: "https://explorer.aleo.org/",
  },
  {
    name: "Aleo Tools",
    link: "https://aleo.tools/",
  },
  {
    name: "Haruka's Aleo Explorer",
    link: "https://explorer.hamp.app/",
  }
]


const ToolkitPage = () => {
  return (
    <>
      <div>
        <Layout>
          <section className="content-blocks" style={contentBlocksStyles}>
            {contentBlocks.map((block, index) => (
              <a href={block.link} key={index} target="_blank" style={contentBlockStyles} className="content-block">
                {block.name}
              </a>
            ))}
          </section>
      </Layout>
      </div>
    </>
  )
}

export default ToolkitPage

