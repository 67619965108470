import React from "react"

// Components
import Layout from "../components/Layout"

const InstructionsPage = () => {
  return (
    <>
      <div>
        <Layout>
          <section className="content-list instructions-list">
            <div><span className="gradient-text">01</span>Enter Your Private Key in ENIGMA</div>
            <div><span className="gradient-text">02</span>Search for your <span className="gradient-text">Issued</span>Record Using the Toolkit</div>
            <div><span className="gradient-text">03</span>USE THE DECRYPT RECORD FEATURE IN ALEO TOOLS</div>
            <div><span className="gradient-text">04</span>GET THE FIELDS FROM YOUR DECRYPTED ALEO RECORD</div>
            <div><span className="gradient-text">05</span>CREATE A LEO PROGRAM TO DECRYPT THE OUTPUTS OF ABOVE</div>
            <div><span className="gradient-text">06</span>TRANSLATE TO REVEAL THE NEXT CLUE</div>
            <div><span className="gradient-text">07</span>THINK HARD, MOVE FASTER</div>
            <div><span className="gradient-text">08</span>RANK IN THE TOP 5 FOR A PRIZE</div>
          </section>
      </Layout>
      </div>
    </>
  )
}

export default InstructionsPage