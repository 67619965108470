import React from "react"

// Components
import Layout from "../../components/Layout"
const successStyles = {
  margin: "0px 0 10px",
}

const SuccessPage = () => {
  return (
    <>
      <div>
        <Layout>
          <div className="vertical-form">
            <div className="subheading">NO. 003</div>
            <div className="prompt gradient-text">
              <div style={ successStyles }>Congratulations! You cracked Enigma and secured your spot among the hall of victors.</div>
              <div style={ successStyles }>Check the leaderboard now to see your final ranking. The top 5 competitors will receive a share of the 25,000 Aleo Credit prize pool.</div>
              <div style={ successStyles }>If you placed in the Top 5, proceed to The Big Room to claim your credits and bask in the glory.</div>
            </div>
          </div>
      </Layout>
      </div>
    </>
  )
}

export default SuccessPage