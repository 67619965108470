// React component for a square button with an outlined look
//
// Path: app/src/components/button.tsx

import { ConfigProvider, Spin } from "antd"
import React from "react"



// Button Component that returns a button with a link
const Spinner = () => {
  return (
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#ffffff',
      },
    }}
    >
      <div className="spinner">
        <Spin size="large" />
      </div>
    </ConfigProvider>
  )
}

export default Spinner