
import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HEALTHCHECK_ENDPOINT } from '../lib/constants';
import { getLocalStorageItem, removeLocalStorageItem } from "../lib/localStorage"; // adjust the import based on your file structure

const footerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: "20px",
  // borderTop: "1px solid #F5F5F5",
  position: "absolute",
  bottom: 0,
  width: "calc(100% - 40px)",
  fontSize: "20px",
  letterSpacing: "1px",
}

const logoutStyles = {
  padding: "10px",
  margin: "10px",
  cursor: "pointer",
}

const networkStatusStyles = {
  fontSize: "12px",
}

const Footer = () => {
  const navigate = useNavigate();
  const storedKey = getLocalStorageItem("privateKey");
  const [networkStatus, setNetworkStatus] = useState("online");

  useEffect(() => {
    // detect if api connection is available
    axios.get(HEALTHCHECK_ENDPOINT).then((res) => {
      setNetworkStatus("online");
    }).catch((err) => {
      console.log('err', err);
      setNetworkStatus("offline");
    })
  }, []);

  const clearStorage = () => {
    removeLocalStorageItem("privateKey");
    removeLocalStorageItem("account");
    // navigate to the home page using react router
    navigate("/play/login");

    
    // window.location.reload(); // reload the page to reflect the changes
  };

  return (
    <footer style={footerStyles}>
      <div className="aleo-font">
        { storedKey ? 
          <>
              {/* <div>Private Key: {storedKey}</div> */}
              <div className="footer-link" style={logoutStyles} onClick={clearStorage}>Logout</div>
          </>
          : 
          ""
        }
        <div className="network-status" style={networkStatusStyles}>API Status: <a href="https://status.aleo.org/" className="gradient-text">{ networkStatus }</a></div>
      </div>
    </footer>
  );
};

export default Footer;
