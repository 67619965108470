import React from "react"

// Components
import Button from "../../components/Button"
import Layout from "../../components/Layout"

const PromptPage3 = () => {
  return (
    <>
      <div>
        <Layout>
          <div className="vertical-form">
            <div className="subheading">NO. 002</div>
            <div className="prompt">
              <div>Have you located the tome where words are kept?</div>
              <div>A trio of letters marks the path ahead: ZKP</div>
              <div>Look beyond letters, into the realm of numbers that underlie -  and let intuition be your guide.</div>
              <div>Follow where the sums of digits lead, and you shall uncover the key.</div>
            </div>
            <Button to="/play/decrypt2" text="Continue" alt="Continue" />
          </div>
      </Layout>
      </div>
    </>
  )
}

export default PromptPage3