import CryptoJS from 'crypto-js';
import { SECRET_KEY } from './constants.js';

const isBrowser = typeof window !== 'undefined';
export const setLocalStorageItem = (key, value) => {
  if (!isBrowser) return;
  const encryptedValue = CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
  localStorage.setItem(key, encryptedValue);
};
export const getLocalStorageItem = (key) => {
  if (!isBrowser) return null;
  const encryptedValue = localStorage.getItem(key);
  if (!encryptedValue) return null;
  const bytes = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY);
  const originalValue = bytes.toString(CryptoJS.enc.Utf8);
  return originalValue;
};
export const removeLocalStorageItem = (key) => {
  if (!isBrowser) return;
  localStorage.removeItem(key);
};
