import { Select } from "antd";
import React, { useEffect, useState } from "react";
import init, { Field, fields_to_string_wasm } from '../lib/fields_to_string/fields_to_string.js';

// Components
import Input from "../components/Input";
import Layout from "../components/Layout";

// Assets
import SohoImage from "../images/Soho-Hints.png";

const defaultFields = "179241519064151292347448995300448802421933314197149837361948765129267047504field, 214012108336877860887926214289400472299522594930224245907455942981363659808field, 177474131641997717176074507137377957014235487572779180186871857101838251375field, 51013162202144field"

const keys = [
  { key: 1, value: "1916219464881281318572138032542990929595039345339394867871718548780200363383field"},
  { key: 2, value: "4434250470183466262436129006077493277249761910353162102155084187687601088503field" },
  { key: 3, value: "5106213031801479134231406769197832520807895144486846982467826755193611454004field" },
  { key: 4, value: "1954721685888008505382641276139222080234775791962585302640081000113336967276field" },
  { key: 5, value: "4533676176779070890122348832882930581520742916891785362191704181277606092562field" },
  { key: 6, value: "7148872312650919182092746093815280391489268891538209423666859496942567895432field" },
  { key: 7, value: "6227204052678992325440175554260789561979480938295888732233461078080684200577field" },
  { key: 8, value: "2905522494662992307860998887477122909776380668276541989079041602540635085316field" },
  { key: 9, value: "4076906798942234292363910885910303775967771320694726476743907459819477867327field" },
  { key: 10, value: "2422974637431677600073444952295570984352372593129170975638911150535304571142field" },
  { key: 11, value: "8044431919056274921615916990411054880697785398950246587101772611661616539470field" },
  { key: 12, value: "2534861766142930542056268753820546058843434861683280460430757093922923874014field" },
  { key: 13, value: "2475615442059394363220460714646395775724334742032600183418796096886300601592field" },
  { key: 14, value: "3396072283858897409996099810040458303130222489017259628583791255616270842702field" },
  { key: 15, value: "6464501813422657827050667484630978039575754636174820396059575517703599585986field" },
  { key: 16, value: "294408679008928019914242745840747284738811779790226252689410699792320858605field" }
]

const keyStyles = {
  fontSize: "20px",
  marginTop: "20px",
  wordBreak: "break-all",
  textTransform: "lowercase",
}

const sohoImageStyles = {
  width: "100%",
  height: "auto",
  marginTop: "20px",
  maxWidth: "100%",
}

const sohoHintsStyles = {
  marginTop: "40px",
}

const spinnerWrapperStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "40px",
}

const HintsPage = () => {
  const [selectedKey, setSelectedKey] = useState(null)
  const [formHint, setFormHint] = useState("")
  const [formStatus, setFormStatus] = useState("default")
  const [message, setMessage] = useState(defaultFields)
  const [loading, setLoading] = useState(false)

  // Initialize the Wasm module when the component mounts
  // useEffect(() => {
  //   init().catch(e => {
  //     console.error('Error initializing the wasm module:', e);
  //   });
  // }, []);

  // useEffect(() => {
  //   handleTranslatorChange();
  // }, []);

  useEffect(() => {
    let isModuleReady = false;
  
    init().then(() => {
      isModuleReady = true;
      let e = {target: {value: defaultFields}}
      handleTranslatorChange(e);  // Call after confirming init is complete
    }).catch(e => {
      console.error('Error initializing the wasm module:', e);
    });
  
    return () => {
      isModuleReady = false;
    };
  }, []);


  const handleTranslatorChange = (e) => {
    try {
      const input = e.target.value;
      setMessage(input);
      const fields = [];
      // strip whitespace from message 
      let strippedMessage = e.target.value.replace(/\s/g, '');
      
      // if message starts or ends with [ or ] remove them
      strippedMessage = strippedMessage.replace(/[\[\]]+/g, '');
      
      // split message where there is a comma
      const splitArray = strippedMessage.split(',');
    
      // loop through split array and create fields
      splitArray.forEach((_field) => {
        if (_field.endsWith("field")) {
          const field = Field.fromString(_field);
          if (!field) {
            return
          }
          fields.push(field);
        }
      });
      const stringFromFields = fields_to_string_wasm(fields);
      setFormHint(stringFromFields)
      setFormStatus("success")
    } catch (e) {
      console.log('error', e);
      setFormHint("Error: Invalid Field")
      setFormStatus("error")
    }
  }

  return (
    <>
      <div>
        <Layout>
        <section className="content-list">
            <div className="aleo-font gradient-text">keys</div>
            <div>
              <Select
                style={{ width: 200 }}
                placeholder="Select a key"
                optionFilterProp="children"
                onChange={(value) => setSelectedKey(value)}

                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {keys.map((key) => (
                  <Select.Option key={key.key} value={key.value}>Key {key.key}</Select.Option>
                ))}
              </Select>
              { selectedKey && (
                <div className="aleo-font" style={keyStyles}>
                  {selectedKey}
                </div>
                )
              }
            </div>
            <div className="soho-hints" style={sohoHintsStyles}>
              <div className="aleo-font gradient-text" style={{marginBottom: "20px"}}>translator</div>
              <div className="translator-hint">
                <Input hint={formHint} rows={6} placeholder="ENTER FIELDS TO TRANSLATE" align="left" state={formStatus} value={message} onChange={handleTranslatorChange} />
              </div>

            </div>
            <div className="soho-hints" style={sohoHintsStyles}>
              <div className="aleo-font gradient-text">helpful code</div>
              <pre className="code-block">snarkos developer scan -v VIEW_KEY --start 0 --end 1 --endpoint "https://api.explorer.aleo.org/v1</pre>
              <pre className="code-block">
                Hmmm...what if you reverse engineered this... 
                <a href="https://github.com/santiagoaleo/decrypt-hint/blob/main/EncryptionFunction" target="_blank" className="gradient-text aleo-font aleo-link">Encryption Function</a>
              </pre>
            </div>
            <div className="soho-hints" style={sohoHintsStyles}>
              <div className="aleo-font gradient-text">map</div>
              <img src={SohoImage} alt="Soho" style={sohoImageStyles} /> 
            </div>
          </section>
      </Layout>
      </div>
    </>
  )
}

export default HintsPage
